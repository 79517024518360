export default {
  data: () => ({
    smsStaticTemplate: {
      id: 0,
      name: "",
    },
    // for_old_client: false,
    loading: false,
    backUrl: "/sms-static-template",
    isBodyLimitExceeded: false,
  }),
  watch: {
    "smsStaticTemplate.body": function (newBody) {
      // Watch for changes in 'body' and limit the input length
      if (newBody.length > 160) {
        this.smsStaticTemplate.body = newBody.substring(0, 160);
        this.isBodyLimitExceeded = true;
      } else {
        this.isBodyLimitExceeded = false;
      }
    },
  },
  methods: {
    formatString(input) {
      // Split the string by underscore, capitalize each word, and join them back with a space
      return input
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    },
    handleInput() {
      // Reset the error when the user inputs characters
      this.isBodyLimitExceeded = false;
    },
    resetForm() {
      this.$refs.observer.reset();
      this.smsStaticTemplate = {
        id: 0,
        name: "",
      };
    },
    validateSmsStaticTemplate() {
      this.smsStaticTemplate.id > 0 ? this.update() : this.add();
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 10000);
    },
    update() {
      // This function updates the bank account details
      let _vm = this;
      this.axios
        .post("/sms-static-templates/" + this.smsStaticTemplate.id, {
          ...this.smsStaticTemplate,
          _method: "PATCH",
        })
        .then(function (response) {
          _vm.smsStaticTemplate = response.data.data;
          _vm.$router.push(_vm.backUrl);
        })
        .catch(function () { });
    },

    add() {
      let _vm = this;
      this.axios
        .post("/sms-static-templates", _vm.smsStaticTemplate)
        .then(function () {
          _vm.$router.push(_vm.backUrl);
        })
        .catch(function () { });
    },
    getDetail() {
      let _vm = this;
      this.axios
        .get("/sms-static-templates/" + this.$route.params.id)
        .then(function (response) {
          _vm.smsStaticTemplate = response.data.data;
        })
        .catch(function () { });
    },
  },
  mounted() {
    if (this.$route.params.id > 0) {
      this.getDetail();
    }
  },
};
